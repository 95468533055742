.aside {
    width: 30%;
    max-width: 300px;
    height: 100%;

    & > *:not(:last-child) {
        margin-bottom: 20px;
    }

    @include mobile {
        width: 100%;
        max-width: 100%;

        & + * {
            margin-top: 25px;
        }
    }
}

.contacts {
    &__aside {
        background-color: $black-color;
        color: $white-color;

        &-wrap {
            @include mobile {
                width: 100%;
                margin-left: 0;
            }
        }

        &-heading {
            display: block;
            padding: 12px 15px;
            font-size: 17px;
            font-weight: 400;
            background-color: $green-color;
        }

        &-info {
            padding: 12px 15px;
            font-weight: 500;
        }
        
        a {
            text-decoration: underline;
        }
    }
}