.header__nav {
    position: relative;

    @include mobile {
        flex: 1;
        margin-left: 10px;
    }

    &._open {
        position: fixed;
        z-index: 123123;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        margin-left: 0;
        background-color: rgba(72, 59, 51, 0.85);

        .header__nav-list {
            display: block;
        }

        .header__nav-mob__btn {
            border-left: 1px solid rgba(72, 59, 51, 0.85);

            &:before, &:after {
                top: 45%;
            }
            &:before {
                transform: rotate(45deg);
            }
            &:after {
                transform: rotate(-45deg);
            }

            span {
                opacity: 0;
            }
        }
    }

    &-list {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 83px;

        @include tablet {
            height: 60px;
        }
        @include mobile {
            display: none;
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-start;
            top: 45px;
            width: 100%;
            height: 100%;
            overflow: auto;
            background-color: rgba(72, 59, 51, 0.7);
        }
    }

    &-item {
        position: relative;

        @include tablet {
            height: 100%;
        }
        @include mobile {
            width: 100%;
        }

        &._sublist {
            > a {
                position: relative;
                padding-right: 25px;

                @include tablet {
                    padding-right: 15px;
                }

                &:after {
                    content: '';
                    @include align(false, true);
                    right: 10px;
                    width: 10px;
                    height: 10px;
                    background-image: url(../img/svg/arrow-down.svg);
                    background-repeat: no-repeat;
                    background-size: contain;

                    @include tablet {
                        top: 49%;
                        right: 0;
                        width: 8px;
                        height: 8px;
                    }
                    @include mobile {
                        content: none;
                    }
                }
                &:hover {
                    &:after {
                        background-image: url(../img/svg/arrow-down-green.svg);
                    }
                }
            }

            &.active {
                > a {
                    &:after {
                        background-image: url(../img/svg/arrow-down-green.svg);
                    }
                }
            }
        }

        &__sublist {
            display: none;
            position: absolute;
            z-index: 100000;
            top: 47px;
            left: 0;
            background-color: $white-color;

            @include tablet {
                top: 60px;
            }
            @include mobile {
                display: block;
                position: static;
                background-color: transparent;
            }
        }

        &__subitem {
            border-bottom: 1px solid $black-color;

            &.active > a {
                color: $green-color;
            }

            & > a {
                display: inline-block;
                width: 100%;
                padding: 7px 15px;
                line-height: 25px;
                text-transform: uppercase;
                font-size: 12px;
                font-weight: 700;

                @include mobile {
                    color: $white-color;
                    font-weight: 400;
                    padding-left: 25px;
                }

                &:hover {
                    color: $green-color;
                }
            }
        }

        &.active > a {
            color: $green-color;
        }

        &:hover > .header__nav-item__sublist {
            display: block;
        }

        & > a {
            padding: 34px 15px;
            cursor: pointer;
            color: $dark-bg-color;
            font-weight: 700;
            text-transform: uppercase;
            font-size: 12px;

            @include tablet {
                display: inline-block;
                margin: auto 0;
                padding: 24px 8px;
                font-size: 10px;
                line-height: 12px;
            }
            @include mobile {
                display: inline-block;
                width: 100%;
                padding: 10px;
                font-weight: 500;
                color: $white-color;
                font-size: 14px;
                line-height: 22px;
                border-bottom: 1px solid $black-color;
            }

            &:hover {
                color: $green-color;
            }
        }

        &--green {
            padding: 10px;
            background-color: $green-color;

            @include tablet {
                padding: 0;
                background-color: transparent;
            }

            & > a {
                border-color: $green-color;
                color: $white-color;

                @include tablet {
                    padding: 8px;
                    margin: 16px 0;
                    background-color: $green-color;
                }

                &:hover {
                    border-color: $black-color;
                    color: $white-color;
                }
            }
        }
    }

    &-mob {
        @include laptop-and-desctop {
            display: none;
        }
        @include tablet {
            display: none;
        }

        &__btn {
            position: relative;
            width: 45px;
            height: 45px;
            padding: 10px;
            color: #fff;
            text-align: center;
            line-height: 45px;
            border-left: 1px solid $white-color;

            &:before {
                top: 12px;
            }

            &:after {
                top: calc(100% - 16px);
            }

            span, &:before, &:after {
                display: block;
                position: absolute;
                width: 25px;
                height: 4px;
                background-color: $white-color;
                border-radius: 1px;
                opacity: 1;
            }

            &:before, &:after {
                content: "";
                transition: top .35s cubic-bezier(.23, 1, .32, 1), transform .35s cubic-bezier(.23, 1, .32, 1);
            }

            span {
                top: 50%;
                transform: translateY(-50%);
            }
        }

        & .header__nav-mob__toggle {
            display: flex;
            flex: 1;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            margin: 0;
            border: 0;
            padding: 0 0 0 10px;
            background-color: $black-color;
            text-align: left;
            font-size: 16px;
            line-height: 25px;
            color: $white-color;
            outline: none;
            font-weight: 500;
        }
    }
}