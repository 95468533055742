.btn {
    display: inline-block;
    border: 2px solid transparent;
    padding: 8px 15px;
    transition: 0.15s ease-in-out;
    vertical-align: middle;
    cursor: pointer;
    text-align: center;

    font-size: 13px;
    line-height: 22px;
    font-weight: 500;

    &:hover, &:focus {
        text-decoration: none;
    }

    svg {
        display: inline-block;
        position: relative;
        min-height: 1px;
        vertical-align: middle;
        margin-right: 5px;
        margin-top: -2px;
    }

    span + svg {
        margin-right: 0;
        margin-left: 5px;
    }

    &-primary {
        background-color: transparent;
        border-color: #898989;
        color: #898989;

        &:hover {
            background-color: transparent;
            border-color: $green-color;
            color: $green-color;
        }
    }

    &-secondary {
        background-color: $green-color;
        border-color: $green-color;
        color: $white-color;

        &:hover {
            background-color: $black-color;
            border-color: $black-color;
            color: $white-color;
        }
    }
}
@keyframes clockwise {
    to {
        transform: rotate(360deg) translateZ(0);
    }
}