.culture {
    background-repeat: no-repeat;
    background-position: center top;
    background-attachment: fixed;
    background-size: cover;
    padding: 60px 0;

    &__grid {
        display: flex;
        flex-wrap: wrap;
        margin-right: -10px;
        margin-left: -10px;

        @include mobile {
            flex-direction: column;
        }
    }
}

.webp .culture {
    background-image: url(../img/index-page/crops-bg.webp);
}
.no-webp .culture {
    background-image: url(../img/index-page/crops-bg.jpg);
}