.grid {
    &__wrap {
        display: flex;
        flex-wrap: wrap;
        margin-right: -10px;
        margin-left: -10px;
        margin-bottom: 20px;

        @include mobile {
            margin-right: -10px;
            margin-left: -10px;
        }
    }

    &__item {
        display: block;
        width: 33.3%;
        margin-bottom: 20px;

        @include mobile {
            width: 50%;
            margin-bottom: 10px;
        }
        @include mobile-m {
            width: 100%;
        }
        @include mobile-s {
            width: 100%;
        }
    }
}