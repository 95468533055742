ul, ol, nav {
    margin: 0;
    padding: 0;
    list-style-type: none;
}

a {
    color: inherit;
    text-decoration: none;
    transition: 0.3s;

    &:hover, &:focus {
        color: inherit;
        text-decoration: none;
    }
}

img {
    max-width: 100%;
}

h1, h2, h3, h4, h5, p, span, small {
    user-select: none;
    pointer-events: none;
}

.h1 {
    margin: 0;
    font-weight: 700;
    font-family: "Montserrat", sans-serif;
    font-size: 79px;
    line-height: 1;

    @include mobile {
        font-size: 27px;
        line-height: 36px;
    }
}

.h2 {
    margin: 0;
    font-weight: 700;
    font-size: 50px;
    line-height: 55px;

    @include mobile {
        font-size: 22px;
        line-height: 30px;
    }
}

.h3 {
    margin: 0;
    font-weight: 700;
    font-size: 40px;
    line-height: 46px;

    @include mobile {
        font-size: 18px;
        line-height: 24px;
    }
}

.h4 {
    margin: 0;
    font-weight: 700;
    font-size: 24px;
    line-height: 28px;

    @include mobile {
        font-size: 15px;
        line-height: 20px;
    }
}

p {
    line-height: 22px;
    margin-bottom: 18px;

    @include mobile {
        font-size: 13px;
        line-height: 19px;
    }

    &:last-child {
        margin-bottom: 0;
    }
}

b, strong {
    font-weight: 700;
}
