//Container Width

$desktopWidth: 1200px;
$laptopWidth: 1020px;
$tabletWidth: 700px;

$laptopMDWidth: 880px;

//Media query

$laptopMedia: 1366px;
$tabletMedia: 1024px;
$mobileMedia: 767px;
$mobileMMedia: 424px;
$mobileSMedia: 374px;

//Container gutter

$gutter: 20px;
$gutter-mobile: 10px;


// Colors
$white-color: #fbfbfb;
$black-color: #171717;
$green-color: #69af07;
$dark-color: #483b33; //main heading color
$dark-second-color: #5f5f5f; //main text color
$dark-bg-color: #1d2126;
$grey-color: #706f6f; //light bg
$grey-second-color: #9e9f9f; //dark bg