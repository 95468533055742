.footer {
    background-color: $dark-bg-color;
    color: $white-color;

    address {
        font-style: normal;
    }

    .copyright {
        &__wrap {
            border-top: 1px solid #33373b;
            padding: 25px 0;
            color: #929292;
        }

        &__inner {
            display: flex;
            justify-content: space-between;

            @include mobile {
                flex-direction: column;
            }
        }

        &__text {
            margin: 0;

            @include mobile {
                margin-bottom: 10px;
            }
        }
    }

    .developer {
        a {
            display: flex;
            user-select: all;
            pointer-events: all;
            transition: 0s;

            &:hover {
                color: darken($white-color, 25%);

                svg {
                    fill: $green-color;
                }
            }

            svg {
                width: 14px;
                height: 22px;
                margin-left: 10px;
                fill: darken($green-color, 8%);

                @include mobile {
                    width: 12px;
                    height: 19px;
                }
            }
        }
    }

    &__row {
        display: flex;
        flex-wrap: wrap;

        @include mobile {
            flex-direction: column;
        }
    }

    &__col {
        width: 50%;
        padding: 30px 20px 30px 0;

        @include mobile {
            width: 100%;
            padding: 30px 0;
        }

        &:last-child {
            padding: 30px 0 30px 20px;
            border-left: 1px solid #33373b;

            @include mobile {
                padding: 30px 0;
                border: none;
            }

            .footer__col-inner {
                text-align: right;

                @include mobile {
                    text-align: left;
                }
            }
        }

        &-inner {
            display: flex;
            flex-direction: column;
        }
    }

    &__heading {
        margin: 15px 0 35px;
        font-size: 20px;
        text-transform: uppercase;
    }

    &__about {
        &-text {
            line-height: 22px;
            font-weight: 500;
            color: $grey-second-color;
            margin-bottom: 20px;
        }

        &-social {
            display: flex;

            &-icon {
                display: inline-block;
                position: relative;
                width: 40px;
                height: 40px;
                border-radius: 50%;
                background-color: $green-color;
                margin-right: 10px;
                text-indent: -999em;

                &:before {
                    @include align();
                    content: "";
                    width: 25px;
                    height: 25px;
                    background-size: contain;
                    background-repeat: no-repeat;
                    background-position: center center;
                }

                &--instagram {
                    &:before {
                        background-image: url(../img/svg/footer-instagram-lg.svg);
                    }
                }
                &--vk {
                    &:before {
                        margin-top: 1px;
                        background-image: url(../img/svg/footer-vk-lg.svg);
                    }
                }
                &--youtube {
                    &:before {
                        background-image: url(../img/svg/footer-youtube-lg.svg);
                    }
                }
            }

            li:last-child .footer__about-social-icon {
                margin-right: 0;
            }
        }
    }


    &__contacts {
        &-text {
            line-height: 22px;
            font-weight: 500;
            color: $grey-second-color;
        }

        &-item {
            @include mobile {
                justify-content: flex-start !important;
            }

            a, span {
                font-weight: 500;
                line-height: 17px;
                color: $grey-second-color;

                @include mobile {
                    padding-right: 0 !important;
                    padding-left: 32px;
                }

                &:hover {
                    color: $green-color;
                }

                &:before {
                    @include mobile {
                        left: 0 !important;
                    }
                }
            }
        }
    }
}

