@mixin clearfix() {
    &:before, &:after {
        content: " ";
        display: table;
    }
    &:after {
        clear: both;
    }
}

@mixin align($horizontal: true, $vertical: true, $transform: null) {
    position: absolute;

    @if ($horizontal) {
        left: 50%;
    }
    @if ($vertical) {
        top: 50%;
    }

    @if ($horizontal and $vertical) {
        transform: translate(-50%, -50%), $transform;
    }
    @else if ($horizontal) {
        transform: translate(-50%, 0), $transform;
    }
    @else {
        transform: translate(0, -50%), $transform;
    }
}

@mixin digital_list() {
    list-style-type: none;
    counter-reset: li-counter;

    li {
        position: relative;
    }

    li::before {
        content: counter(li-counter);
        counter-increment: li-counter;
        display: inline-block !important;
    }
}

@mixin fill($digit: 0) {
    position: absolute;
    top: $digit;
    right: $digit;
    bottom: $digit;
    left: $digit;
}

@mixin filter() {
    filter: grayscale(1) brightness(1.15);
    opacity: 0.65;
}

@mixin letter-spacing($photoshop) {
    letter-spacing: #{$photoshop / 1000}em;
}

@mixin link-underline {
    text-decoration: underline;
    &:hover {
        text-decoration: none;
    }
}

@mixin placeholder {
    &::-webkit-input-placeholder {
        @content;
    }
    &:-moz-placeholder {
        @content;
    }
    &::-moz-placeholder {
        @content;
    }
    &:-ms-input-placeholder {
        @content;
    }
}

@mixin rotate($deg: 180deg) {
    transform: rotate($deg);
}

@mixin transition($property: all) {
    transition: $property 0.3s linear;
}

@mixin triangle($width, $dir, $color) {
    @if $dir == up {
        width: 0;
        height: 0;
        border-left: $width/2 solid transparent;
        border-right: $width/2 solid transparent;
        border-bottom: $width/2 solid $color;
    }
    @else if $dir == down {
        width: 0;
        height: 0;
        border-left: $width/2 solid transparent;
        border-right: $width/2 solid transparent;
        border-top: $width/2 solid $color;
    }
    @else if $dir == right {
        width: 0;
        height: 0;
        border-top: $width solid transparent;
        border-bottom: $width solid transparent;
        border-left: $width solid $color;
    }
    @else if $dir == left {
        width: 0;
        height: 0;
        border-top: $width solid transparent;
        border-bottom: $width solid transparent;
        border-right: $width solid $color;
    }
}

@mixin sticky-footer(
    $footer-height,
  $wrapper-selector: unquote("#wrapper"),
  $container-selector: unquote("#container"),
  $footer-selector: unquote("#footer")
) {
    html, body {
        height: 100%;
    }
    body {
        margin: 0;
        padding: 0;
    }
    #{$wrapper-selector} {
        height: auto !important;
        min-height: 100%;
        margin-bottom: -$footer-height;
        #{$container-selector} {
            padding: 0 0 $footer-height 0;
        }
    }
    #{$footer-selector} {
        min-height: $footer-height;
    }
}

@mixin mobile-s() {
    @media (max-width: $mobileSMedia) {
        @content;
    }
}

@mixin mobile-m() {
    @media (min-width: $mobileSMedia) and (max-width: $mobileMMedia) {
        @content;
    }
}

@mixin mobile-l() {
    @media (min-width: $mobileMMedia) and (max-width: $mobileMedia) {
        @content;
    }
}

@mixin mobile() {
    @media (max-width: $mobileMedia) {
        @content;
    }
}

@mixin tablet() {
    @media (min-width: $mobileMedia + 1) and (max-width: $tabletMedia) {
        @content;
    }
}

@mixin tablet-and-phone() {
    @media (max-width: $tabletMedia) {
        @content;
    }
}

@mixin laptop() {
    @media (min-width: $tabletMedia + 1) and (max-width: $laptopMedia) {
        @content;
    }
}

@mixin desktop() {
    @media (min-width: $laptopMedia + 1) {
        @content;
    }
}

@mixin laptop-and-desctop() {
    @media (min-width: $tabletMedia + 1) {
        @content;
    }
}

@function hsla-calc($color, $opacity) {
    @return hsla(hue($color), saturation($color), lightness($color), $opacity);
}