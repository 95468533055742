.img-group {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;

    img {
        display: inline-block;
        max-width: 400px;
        margin: 10px;

        @include tablet {
            max-width: 300px;
        }
        @include mobile {
            max-width: 140px;
            margin: 5px;
        }
    }
}