@import "header/header";
@import "footer/footer";
@import "nav/nav";

/* Index Page */
@import "main-slider/main-slider";
@import "about-section/about-section";
@import "advantages-section/advantages-section";
@import "why-us-section/why-us-section";
@import "crops-section/crops-section";
@import "last-news-section/last-news-section";


@import "contacts/contacts-list";
@import "contacts/contacts";


@import "news/news";


@import "about-gallery/about-gallery";


/**/
@import "aside/aside";


@import "catalog/catalog-item";
@import "product/product";