.heading {
    font-size: 30px;
    line-height: 40px;
}

.text {
    font-size: 13px;
    line-height: 25px;
}

.page {
    &__heading {
        position: relative;
        padding-bottom: 19px;
        margin-bottom: 30px;
        font-weight: 600;
        font-size: 30px;
        text-transform: capitalize;
        text-align: left;
        color: $dark-color;

        &:after {
            content: "";
            position: absolute;
            right: 0;
            bottom: 0;
            left: 0;
            width: 50px;
            height: 2px;
            background-color: $dark-color;
        }
    }
    &__sub-heading {
        position: relative;
        padding-bottom: 15px;
        margin-bottom: 20px;
        font-weight: 600;
        font-size: 25px;
        text-align: left;
        color: $dark-color;

        &:after {
            content: "";
            position: absolute;
            right: 0;
            bottom: 0;
            left: 0;
            width: 50px;
            height: 2px;
            background-color: $green-color;
        }
    }
}

.main {
    &__heading {
        position: relative;
        padding-bottom: 15px;
        margin-bottom: 30px;
        font-weight: 700;
        font-size: 30px;
        color: $dark-color;
        text-align: center;
        text-transform: uppercase;

        &:after {
            content: "";
            position: absolute;
            right: 0;
            bottom: 0;
            left: 0;
            width: 67px;
            height: 3px;
            margin: 0 auto;
            background-color: $green-color;
        }
    }
}

.heading-light {
    color: $white-color;
}