.advantages {
    display: flex;
    height: 400px;
    color: $white-color;

    @include tablet {
        height: 350px;
    }
    @include mobile {
        flex-direction: column;
        height: auto;
    }

    &__col {
        position: relative;
        width: 33.3%;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;

        @include mobile {
            width: 100%;
            height: 300px;
        }
        @include mobile-s {
            height: 320px;
        }

        &:last-child {
            width: 33.4%;

            @include mobile {
                width: 100%;
            }
        }

        &:nth-child(even) {
            .advantages__descr {
                background-color: rgba(72, 59, 51, .7);
            }
        }
    }

    &__descr {
        position: absolute;
        z-index: 20;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: rgba(105, 175, 7, .7);

        &-content {
            position: absolute;
            top: 50%;
            width: 100%;
            padding: 0 35px;
            transform: translateY(-50%);

            @include mobile {
                padding: 0 15px;
            }
        }

        &-text {
            text-align: center;
            font-size: 14px;
        }
    }

    &__title {
        margin: 0;
        font-size: 30px;
        font-weight: 700;
        text-align: center;

        @include tablet {
            font-size: 26px;
        }

        & + * {
            margin-top: 20px;
        }
    }
}

.webp .advantages {
    &__col {
        &--1 {
            background-image: url(../img/index-page/advantages-1.webp);
        }
        &--2 {
            background-image: url(../img/index-page/advantages-2.webp);
        }
        &--3 {
            background-image: url(../img/index-page/advantages-3.webp);
        }
    }
}
.no-webp .advantages {
    &__col {
        &--1 {
            background-image: url(../img/index-page/advantages-1.jpg);
        }
        &--2 {
            background-image: url(../img/index-page/advantages-2.jpg);
        }
        &--3 {
            background-image: url(../img/index-page/advantages-3.jpg);
        }
    }
}