.catalog {
    &__wrap {
        display: flex;
        padding: 40px 0;

        @include mobile {
            flex-direction: column;
        }
    }

    &__inner {
        flex: 1;
        margin-left: 40px;

        @include mobile {
            margin-left: 0;
        }

        &.w-100 {
            margin-left: 0;
        }
    }
}