.catalog__item {
    display: block;
    position: relative;
    width: calc(33.3% - 20px);
    overflow: hidden;
    margin: 10px;

    @include tablet {
        width: calc(50% - 20px);
    }
    @include mobile {
        width: calc(100% - 20px);
        margin-bottom: 20px;
    }
    @include mobile-l {
        width: 100%;
    }

    &:before {
        content: "";
        position: absolute;
        z-index: 1;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        transition: all .3s;
        opacity: 0;
        background-color: rgba(105, 175, 7, .3);
    }

    &:hover {
        &:before {
            opacity: 1;
        }

        img {
            transform: scale(1.2);
            filter: blur(2px);
        }
    }

    img {
        display: block;
        width: 100%;
        max-width: 100%;
        height: 160px;
        transition: all .5s;
        object-fit: cover;
    }

    &-name {
        position: absolute;
        bottom: 0;
        left: 0;
        min-width: 10%;
        border: 2px solid $green-color;
        padding: 10px 20px;
        background-color: $green-color;

        span {
            font-size: 16px;
            font-weight: 500;
            color: $white-color;
        }
    }

    &--lg {
        .catalog__item-name span {
            font-size: 20px;
        }

        img {
            height: 200px;
        }
    }
}