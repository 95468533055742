.last-news {
    padding: 60px 0;
    text-align: center;

    &__row {
        display: flex;

        @include tablet-and-phone {
            flex-wrap: wrap;
        }
    }

    &__btn {
        margin-top: 10px;
    }
}