.about {
    margin: 60px 0;

    &__row {
        display: flex;

        @include tablet-and-phone {
            flex-direction: column;
        }
    }

    &__video {
        height: 0;
        position: relative;
        padding-bottom: 57.25%;
        margin-top: 40px;
        pointer-events: none;
        user-select: none;

        @include tablet-and-phone {
            pointer-events: all;
            user-select: all;
        }
        @include mobile {
            padding-bottom: 59.25%;
        }

        & iframe {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            border: 2px solid #69af07;
            padding: 10px;
        }
    }

    &__photo-col {
        display: flex;
        justify-content: center;
        width: 45%;
        margin-right: 70px;

        @include tablet-and-phone {
            width: 100%;
            margin-right: 0;
            margin-bottom: 30px;
        }

        img {
            width: auto;
            max-width: 520px;

            @include mobile {
                max-width: 320px;
            }
        }
    }

    &__info-col {
        width: 49%;
        padding: 0;

        @include tablet-and-phone {
            width: 100%;
        }
    }

    &__heading {
        margin-bottom: 20px;
        color: $dark-color;
        font-weight: 700;
        font-size: 30px;
        line-height: 40px;
    }

    &__info {
        max-width: 520px;
        margin-bottom: 20px;
        font-size: 14px;
        line-height: 22px;
        font-weight: 500;
        color: $grey-color;

        @include tablet-and-phone {
            max-width: 100%;
        }
    }
}