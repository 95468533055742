.product {
    padding: 50px 0;

    &__inner {
        margin-bottom: 60px;
    }

    &__row {
        display: flex;
        margin-bottom: 20px;

        @include tablet-and-phone {
            flex-direction: column;
        }

        &:last-child {
            margin-bottom: 0;
        }
    }

    &__gallery {
        flex: 1 0 auto;
        width: 500px;
        height: 100%;
        border: 2px solid $green-color;
        padding: 10px;
        margin-right: 60px;

        @include tablet-and-phone {
            width: 660px;
            margin: 0 auto 20px;
        }

        @include mobile {
            width: 100%;
        }

        img {
            display: block !important;
            width: 100% !important;
            max-width: 480px;
            max-height: 480px;
            object-fit: contain;
        }

        &-main {
            padding-bottom: 10px;

            .slick-track {
                display: flex;
            }
            .slick-track .slick-slide {
                display: flex;
                align-items: center;
                justify-content: center;
                height: auto;
            }

            .slick-slide > div {
                width: 100%;
            }
        }

        &-nav {
            margin: 0 -10px;
            padding-top: 15px;
            border-top: 2px solid $green-color;

            .slick-track {
                display: flex;
                margin-left: 0;
            }

            .slick-slide {
                border: 1px solid transparent;
                padding: 5px;
                cursor: pointer;

                & > div > picture {
                    display: block !important;
                }
            }

            .slick-track .slick-slide {
                display: flex;
                align-items: center;
                justify-content: center;
                height: auto;
            }

            .slick-current {
                border: 1px solid $green-color;
            }

            img {
                max-height: 88px;
                object-fit: cover;
            }
        }
    }

    &__info {
        &-descr {
            p {
                margin-bottom: 8px;
                font-weight: 400;
                font-size: 18px;
                line-height: 30px;
            }

            ul {
                margin-bottom: 8px;

                li {
                    position: relative;
                    padding-left: 20px;
                    font-weight: 400;
                    font-size: 18px;
                    line-height: 30px;

                    &:before {
                        content: "";
                        position: absolute;
                        top: 12px;
                        left: 5px;
                        width: 6px;
                        height: 6px;
                        border-radius: 50%;
                        background-color: $green-color;
                    }
                }
            }

            *:last-child {
                margin-bottom: 0;
            }
        }
    }

    &__video {
        width: 80%;
        height: 500px;
        margin: 0 auto 60px;

        @include tablet {
            height: 400px;
        }
        @include mobile {
            width: 100%;
            height: 200px;
        }
        @include mobile-l {
            height: 300px;
        }

        iframe {
            width: 100%;
            height: 100%;
            border: 0;
        }
    }
}

.add-info {
    margin-bottom: 60px;

    &__heading {
        position: relative;
        padding-bottom: 20px;
        margin-bottom: 20px;
        font-weight: 600;
        font-size: 30px;
        text-transform: capitalize;
        text-align: left;
        color: $dark-color;

        &:after {
            content: "";
            position: absolute;
            right: 0;
            bottom: 0;
            left: 0;
            width: 50px;
            height: 2px;
            background-color: $dark-color;
        }
    }

    &__descr {
        margin-bottom: 10px;
        font-weight: 400;
        font-size: 18px;
        line-height: 30px;
    }
}

.info-logo {
    &__wrap {
        margin: 20px 0 0;
    }

    &__list {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        width: 80%;
        margin: 0 auto;
    }

    &__item {
        width: 100%;
        max-width: 120px;
        margin: 10px 20px;
    }
}
